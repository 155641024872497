import React from "react";
import "./styles/style.scss";
import { GlobalStyle, theme } from "@teamfabric/copilot-ui";
import { ThemeProvider } from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
// components
import SideNav from "./components/atoms/navigation";
import Main from "./components/organism/main";
import {Header} from "./components/atoms/header";
import MobileOutlets from "./components/molecules/mobile_switch_outlets";
import MobileNavCustom from "./components/molecules/mobile_nav_custom";
import { Toaster } from "react-hot-toast";
import { useNavigatorStatus } from "react-navigator-status";
import AppConnectionAlert from "./components/atoms/connectionAlertUI";
function App() {
  const isOnline = useNavigatorStatus();
  const handleNavigationButtonClick = () => {
    if (document.querySelector(".nav_container")) {
      let element = document.querySelector(".nav_container");
      element.classList.toggle("show");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />

        <Header />

        <Toaster
          position="top-center"
          toastOptions={{
            style: {
              color: "white",
              minWidth: "250px",
              height: "50px",
              fontSize: "14px",
            },
          }}
        />
        <div className="container">
          <MobileNavCustom />
          <MobileOutlets />
          <SideNav />
          <AppConnectionAlert isOnline={isOnline} />
          <Main onNavigationButtonClick={handleNavigationButtonClick} />
        </div>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
