import React, { useState } from "react";
import ShopdeskLogo from "../../svg/shopdesk_logo";
import { useHistory } from 'react-router-dom';
import { 
  getDataFromLocalStorage,
} from "../../../utils/local-storage/local-store-utils";
import Constants from "../../../utils/constants/constants";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export const Header = () => {
  
  const history = useHistory();
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show)
  const handleLogout = () => {
    localStorage.clear();
    history.push("/pos/login");
  }
  let readFromLocalStorage = getDataFromLocalStorage(Constants.USER_DETAILS_KEY);
  readFromLocalStorage = readFromLocalStorage.data && readFromLocalStorage.data;
  const userInfo = readFromLocalStorage?.user_info || {};
  const userName = userInfo?.user_name || 'John Doe';
  function getFirstLetters(str) {
    const firstLetters = str
      .split(' ')
      .map(word => word[0])
      .join('');
  
    return firstLetters;
  }
  const nameInitials =  getFirstLetters(userName);
  return (
    <header className="header">
      <div className="logo">
        <ShopdeskLogo />
      </div>
      <div className="user" onClick={toggleShow}>
        <div className="user__avatar">{nameInitials}</div>
        <div className="user__name">{userName}</div>
        <ul className={`user__dropdown ${show && 'show'}`}>
          <li>
            <div className="user__info">
              <div className="user__avatar">{nameInitials}</div>
              <div className="user__email">
                <span>{userName}</span>
                <span className="email">{userInfo?.email || 'johndoe@google.com'}</span>
              </div>
            </div>
          </li>
          <li className="logout" onClick={handleLogout}>Logout</li>
        </ul>
      </div>
    </header>
  );
}

export const getClientConnection = () => {
  const endpoint = "ws://127.0.0.1:8000";
  const client = new W3CWebSocket(endpoint);
  return client;
};
